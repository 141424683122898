@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;1,100;1,200;1,300;1,400;1,500&display=swap');

html {
	overflow-x: hidden;
}
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	font-family: 'Poppins', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

.gradient-text {
	background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}
.gradient-text-reverse {
	background: linear-gradient(145deg, #2042b8 10.3%, #ff6600 117.2%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

.small-text-gradient {
	background: linear-gradient(141.55deg, #ff6600 -3.79%, #2042b8 82.82%);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	background-clip: text;
	text-fill-color: transparent;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}
::-webkit-scrollbar-track {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background: rgba(0, 0, 0, 0.2);
}
::-webkit-scrollbar-thumb:hover {
	background: rgba(0, 0, 0, 0.4);
}
::-webkit-scrollbar-thumb:active {
	background: rgba(0, 0, 0, 0.9);
}

.pending {
	font-style: italic;
	font-weight: 400;
	line-height: 18px;
	color: #52575c;
}

.text-green {
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #058228;
}
.text-yellow {
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #cab008;
}

.text-red {
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	color: #fb0606;
}
