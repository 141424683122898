.paginationBttns {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  list-style-type: none;
  color: #2042b8;
  text-decoration: none;
  font-style: normal;
  font-weight: 500;
  font-size: 90%;
  line-height: 29px;
  cursor: pointer;
  margin-top: 20px;
}

.paginationActive {
  color: #ff6600;
}

.pagination p:hover:not(.active) {
  background-color: #ddd;
  border-radius: 5px;
}

.change {
  color: #038cfd;
}

.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  height: 40px;
  padding: 0 20px;
  background: #2042b8;
  border-radius: 12px;
  outline: none;
  border: none;
  color: "#fff";
  font-size: 18px;
  font-weight: 400;
  cursor: pointer;
}
@media (max-width: 1024px) {
  .export-button {
    font-size: 12px;
    font-weight: 600;
  }
}
@media (max-width: 576px) {
  .export-button {
    font-size: 10px;
    font-weight: 600;
    height: 35px;
  }
  .export-button img {
    width: 15px;
    height: 15px;
  }
}

.prev {
  color: #2042b8;
  padding: 0px 5px;
  border: 3px solid #2042b8;
  border-radius: 5px;
  outline: none;
  font-weight: 600;
}
.next {
  color: #2042b8;
  padding: 0px 5px;
  border: 3px solid #2042b8;
  border-radius: 5px;
  outline: none;
  font-weight: 600;
}

.card-container {
  width: 100%;
}

.date-picker {
  width: 100%;
  height: 42px;
  background: rgba(32, 66, 184, 0.03);
  border: 1px solid rgba(32, 66, 184, 0.15);
  border-radius: 5px;
  outline: none;
  padding: 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #52575c;
  margin-top: 5px;
}

.flex-center {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
